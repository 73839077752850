// ### Staatliches

@font-face {
  font-family: 'Staatliches';
  src: url('../assets/fonts/staatliches/Staatliches-Regular.ttf') format('truetype');
  font-weight: 400; // normal
  font-style: normal;
}

// ### Open Sans

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300; // light
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300; // light
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400; // normal (regular)
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400; // normal (regular)
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600; // semi bold
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; // semi bold
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700; // bold
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700; // bold
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800; // extra-bold
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800; // extra-bold
  font-style: italic;
}

// ### Raleway

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Thin.ttf') format('truetype');
  font-weight: 100; // thin
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100; // thin
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200; // extra light
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200; // extra light
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Light.ttf') format('truetype');
  font-weight: 300; // light
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300; // light
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Regular.ttf') format('truetype');
  font-weight: 400; // normal (regular)
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Italic.ttf') format('truetype');
  font-weight: 400; // normal (regular)
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Medium.ttf') format('truetype');
  font-weight: 500; // medium
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500; // medium
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600; // semi bold
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; // semi bold
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-Bold.ttf') format('truetype');
  font-weight: 700; // bold
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: 700; // bold
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800; // extra-bold
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/static/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800; // extra-bold
  font-style: italic;
}
