
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import 'variables.scss';

$color-secondary: #f3ad6a; // TODO: --ion-color-secondary
$color-tertiary: #22254c; // TODO: --ion-color-tertiary

.ngx-datatable.material .datatable-header {
  border-bottom-color: $color-secondary;
}

.ngx-datatable.material {
  box-shadow: none;

  .datatable-header {
    datatable-header-cell.datatable-header-cell {
      font-size: 16px;
      color: $color-tertiary;

      .datatable-header-cell-template-wrap {
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;

        .datatable-header-cell-wrapper {
          overflow: hidden;
        }

        .sort-btn {
          &::before {
            display: block;
            margin-top: 4px;
            // border: 1px solid red;
          }
        }
      }

      &.resizeable {
        .resize-handle {
          border-right-color: #eee;
          visibility: visible;
        }

        &:hover .resize-handle {
          border-right-color: $color-secondary;
        }
      }
    }


  }

  .datatable-body {
    scrollbar-width: thin;
    scrollbar-color: #46885c #f3f3f3;

    &::-webkit-scrollbar{
      width: 5px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fcf3f3;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(73, 143, 122, .9);
      border-radius: 10px;
    }

    .datatable-body-row .datatable-body-cell {
      font-size: 16px;
    }
  }

  .datatable-footer {
    font-size: 16px;
    border-top-color: $color-secondary;
    // color: $color-secondary;

    .datatable-footer-inner {
      justify-content: space-between;
    }

    .page-count {
      flex: none;
      width: 400px;
    }

    .datatable-pager {
      text-align: center;
      flex: none;

      li {
        // a {
        //   color: $color-secondary;
        // }
  
        &.active a {
          background-color: rgba(243, 173, 106, 0.2);
          color: $color-secondary;
        }
      }
    }

    .page-limit-container {
      width: 400px;
    }
  }

  .empty-row {
    line-height: 34px;
  }
}