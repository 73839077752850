/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "src/theme/variables.scss";
@import "src/theme/fonts.scss";
@import "src/theme/datatable.scss";

@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Nunito:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

$font-family-bona-nova-sc: "Bona Nova SC", serif;
$font-family-rubik: "Rubik", sans-serif;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-style-normal: normal;

html,
html.ios {
  --ion-default-font: "Open Sans", sans-serif;
  --ion-font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.can-go-back > app-header ion-back-button {
  display: block;
}

.row .col.item-with-borders {
  --border-width: 1px;
}
.ion-page {
  overflow: auto !important;
}
.ion-page .ion-padding {
  --ion-padding: 1rem;
}

// Removing shadow image for header..
ion-header::after {
  background-image: none !important;
}

ion-title.title-default {
  padding: 0 20px;
  text-align: left;
  font-weight: 500;
  position: static;
}

// Styles for ion-list and ion-item..
ion-list {
  --ion-background-color: transparent;

  ion-item {
    --background: transparent;
  }
}

// Styles for ion-card and related elements..

ion-card {
  ion-card-header {
    padding: 16px;
    align-items: center;
  }

  ion-card-title {
    font-size: 20px;
    font-weight: 500;
  }

  ion-card-subtitle,
  ion-card-content .description {
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
  }

  &.menu,
  &.item,
  &.mapping {
    ion-card-subtitle {
      margin: 0.25rem 0;

      &.tags {
        margin-top: 0;
      }
    }
  }
}

// Setting border radius for all popups..
@media only screen and (min-width: 768px) and (min-height: 600px) {
  .sc-ion-modal-md-h {
    --border-radius: 4px;
  }
}

// Setting theme for Model headers..
.modal-wrapper {
  ion-header ion-toolbar.in-toolbar {
    --background: var(--ion-color-primary-tint);
    --color: var(--ion-color-primary-contrast);
  }
  
  // For datepicker modals - ion2-datepicker
  .datepicker-modal {
    ion-header ion-toolbar.in-toolbar {
      --background: var(--ion-color-primary-tint) !important;
      --ion-color-base: var(--ion-color-primary-tint) !important;
    }

    ion-button {
      text-transform: none;
    }
  }
}

// For Segments / Tabs..
ion-segment {
  border-bottom: 1px solid rgba(var(--ion-color-primary-rgb), 0.2);
  
  ion-segment-button {
    --padding-start: 0;
    --padding-end: 0;
    --indicator-height: 50px;
    --indicator-color: rgba(var(--ion-color-primary-rgb), 0.2);
  }

  ion-label {
    text-transform: none;
  }
}

// For buttons..
ion-button {
  text-transform: capitalize !important;
  --box-shadow: none;
}

// For buttons in alert model..
.alert-button-group .alert-button {
  text-transform: none;
}

ion-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.filters {
  padding: 1rem 0.5rem 0.5rem;
  overflow: auto;
  margin-right: 0.6rem;

  ion-item {
    width: 180px;
    border: 1px solid var(--ion-color-primary);
    border-radius: 3px;
    margin: 0.5rem;
    --min-height: 68px;
  }
}

.row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;

  &.sd-spacing {
    padding: 0.5rem;

    > .col {
      padding: 0.5rem;
    }
  }
}

ion-app.camera-view,
ion-app.camera-view ion-content {
  background: transparent none !important;
  display: none;
}

i[slot="icon-only"] {
  font-size: 1.5em;
}

i[slot="start"],
i[slot="end"] {
  font-size: 20px;
}

i[slot="start"] {
  margin-right: 22px;
  margin-left: 2px;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 5px !important;
  border-radius: 6px !important;
}
::-webkit-scrollbar-track {
  background: #010101;
}
::-webkit-scrollbar-thumb {
  background: #dee21b;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #e9f415;
}

.page-title {
  font-size: 28px;
  font-family: $font-family-rubik;
  font-weight: $font-weight-regular !important;
  text-transform: capitalize !important;
  color: #1f232d;
}
@media screen and (max-width: 520px) {
  .page-title {
    font-size: 21px !important;
  }
}
.btn-create-data {
  --background: transparent;
  background-image: linear-gradient(to right, #2c3e50 0%, #3498db  51%, #2c3e50  100%);
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 150px !important;
  height: 40px !important;
  font-weight: 600 !important;
  margin: 5px 10px !important;

  &:hover {
    background-position: right center;
    text-decoration: none;
  }
}

.btn-refresh-data {
  --background: transparent;
  width: 120px !important;
  height: 40px !important;
  background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px #eee;
  margin: 5px !important;
  font-weight: 600 !important;

  &:hover {
    background-position: right center;
    text-decoration: none;
    color: #93ccfe;
  }
}